<template>
  <div class="sb-offer-section">
    <div :class="{ 'inner-container fad': !isMd }">
      <div v-if="selectedOffers.length > 1" class="sb-offer-section-tabs">
        <a
          v-for="(offer, i) in selectedOffers"
          :key="i"
          :data-test-id="`home-pro-offer-tab-${offer.title.replace(/\s+/g, '-').toLowerCase()}`"
          class="sb-offer-section-tabs-item"
          :class="{ active: currentIdx === i }"
          @click="currentIdx = i"
        >
          {{ offer.title }}
        </a>
      </div>
      <template v-for="(offer, i) in selectedOffers" :key="offer.uuid">
        <div v-if="currentIdx === i" class="sb-offer-section-container" data-test-id="home-pro-offer-tab-result">
          <div class="sb-offer-section-container-wrapper">
            <div v-if="offer.main_img" class="sb-offer-section-container-wrapper-left">
              <div class="sb-offer-section-container-wrapper-left-img">
                <ImageLazy :src="offer.main_img.filename" />
              </div>
            </div>

            <div class="sb-offer-section-container-wrapper-right">
              <div v-if="offer.title" class="sb-offer-section-container-wrapper-right-title">
                <span>{{ offer.title }}</span>
              </div>

              <div
                v-if="offer.services"
                class="sb-offer-section-container-wrapper-right-links u-d-f"
                :class="{ compact }"
              >
                <template v-for="(service, idx) in offer.services" :key="idx">
                  <div>
                    <SbButtonsLinkHover
                      v-if="getStoryByUid(service)"
                      :click="true"
                      :link="{
                        url: getStoryByUid(service).full_slug.includes('folder')
                          ? `/services/additional#${getStoryByUid(service).slug}`
                          : `/services/${getStoryByUid(service).slug}`,
                        icon:
                          isSm || compact
                            ? getStoryByUid(service).content && getStoryByUid(service).content.logo
                              ? getStoryByUid(service).content.logo.filename
                              : null
                            : getStoryByUid(service).content && getStoryByUid(service).content.icon
                              ? getStoryByUid(service).content.icon.filename
                              : null,
                        label: getStoryByUid(service).content ? getStoryByUid(service).content.title : null,
                      }"
                    />
                  </div>
                </template>
              </div>

              <div
                v-if="offer.short_description || offer.description"
                class="sb-offer-section-container-wrapper-right-text"
              >
                <span>{{ compact && offer.short_description ? offer.short_description : offer.description }}</span>
              </div>

              <div
                v-if="!compact && offer.buttons && offer.buttons.length"
                class="sb-offer-section-container-wrapper-right-buttons u-d-f u-fw-wrap-md"
              >
                <SbCtaGroup
                  :buttons="selectedOffers.length > 1 ? offer.buttons : offersButtonsToPlans(offer.buttons)"
                />
              </div>

              <div v-if="!compact">
                <div
                  v-if="getTestimonyByUid(offer.testimony) && getTestimonyByUid(offer.testimony).content"
                  class="sb-offer-section-container-wrapper-right-testimony u-d-f"
                >
                  <div
                    v-if="getTestimonyByUid(offer.testimony).content.picture"
                    class="sb-offer-section-container-wrapper-right-testimony-left"
                  >
                    <div class="sb-offer-section-container-wrapper-right-testimony-left-img">
                      <ImageLazy
                        :src="getTestimonyByUid(offer.testimony).content.picture"
                        :alt="getTestimonyByUid(offer.testimony).content.companyName"
                      />
                    </div>
                  </div>

                  <div class="sb-offer-section-container-wrapper-right-testimony-right">
                    <div class="sb-offer-section-container-wrapper-right-testimony-right-name">
                      <span
                        >{{ getTestimonyByUid(offer.testimony).content.firstname }}
                        {{ getTestimonyByUid(offer.testimony).content.lastname }},
                      </span>
                      <span class="sb-offer-section-container-wrapper-right-testimony-right-name-role">
                        {{ getTestimonyByUid(offer.testimony).content.companyName }}
                      </span>
                    </div>

                    <div class="sb-offer-section-container-wrapper-right-testimony-right-text">
                      <span>{{ getTestimonyByUid(offer.testimony).content.text }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <slot />
            </div>
          </div>
          <template v-if="!compact && display_companies && offer.companies && offer.companies.length">
            <div class="sb-offer-section-container-companies-trust">
              {{ $t('proArea.theyTrustUs') }}
            </div>
            <div class="sb-offer-section-container-companies">
              <ImageLazy
                v-for="company in offer.companies"
                :key="company.id"
                :src="company.filename"
                :width="isSm ? '33%' : '12.5%'"
                class-variant="grayscale"
              />
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Mobile from '@/mixins/mobile-mixin'

import SbCtaGroup from '@/components/Storyblok/SbCtaGroup'
import SbButtonsLinkHover from '@/components/Storyblok/SbButtonsLinkHover'

export default {
  name: 'SbOffersSection',
  components: {
    SbCtaGroup,
    SbButtonsLinkHover,
  },
  mixins: [Mobile],
  props: {
    offers: { type: Array, default: () => [] },
    // eslint-disable-next-line vue/prop-name-casing
    display_companies: { type: Boolean, default: false },
    compact: { type: Boolean, default: false },
  },
  data() {
    return {
      currentIdx: 0,
    }
  },
  computed: {
    ...mapGetters('proArea', ['getStoryByUid', 'getTestimonyByUid']),
    selectedOffers() {
      return this.offers.map(x => this.getStoryByUid(x)?.content).filter(x => x)
    },
  },
  methods: {
    offersButtonsToPlans(buttons) {
      const btns = JSON.parse(JSON.stringify(buttons))
      btns[0].title = this.$t('proArea.seePlans')
      btns[0].url = '/tarifs'
      return btns
    },
  },
}
</script>

<style lang="scss">
.sb-offer-section {
  padding: $spacing-xl;
  background-color: var(--white);

  @include mq($mq-xs) {
    padding: $spacing-md;
  }

  &-tabs {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-md;
    justify-content: center;
    margin-bottom: $spacing-lg;
    width: 100%;
    @include mq($mq-xs) {
      gap: $spacing-xs;
    }
    &-item {
      cursor: pointer;
      padding: $spacing-xs $spacing-sm;
      border-radius: $spacing-xxs;
      font-weight: 800;
      font-size: pxToRem(15px);
      &.active {
        background-color: var(--primary);
        color: var(--white);
      }
      &:hover:not(&.active) {
        background-color: var(--bg-grey);
      }
      @include mq($mq-xs) {
        font-size: pxToRem(12px);
      }
    }
  }

  &-container {
    &-wrapper {
      display: flex;
      margin: 0 auto;
      gap: $spacing-md;

      @include mq($mq-sm) {
        flex-direction: column;
      }

      &-left {
        &-img {
          width: 490px;
          height: 400px;
          border-radius: 10px;
          overflow: hidden;

          @include mq($mq-md) {
            width: 322px;
          }

          @include mq($mq-sm) {
            width: 100%;
            height: 275px;
          }
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }

      &-right {
        min-width: 0;
        @include mq($mq-sm) {
          max-width: 100%;
        }
        &-title {
          font-weight: 800;
          font-size: pxToRem(25px);
          color: var(--dark-blue);
          padding-bottom: $spacing-sm;

          @include mq($mq-xs) {
            font-size: pxToRem(20px);
          }
        }

        &-links {
          display: flex;
          flex-wrap: wrap;
          gap: $spacing-sm;
          padding-bottom: $spacing-md;
          @include mq($mq-xs) {
            flex-wrap: nowrap;
            overflow: auto;
            max-width: 100%;
          }
          &.compact {
            .sb-buttons-link-hover-img {
              width: 28px;
              height: 28px;
            }
          }
          .sb-buttons-link-hover-img {
            width: 38px;
            height: 38px;

            @include mq($mq-xs) {
              font-size: pxToRem(20px);
            }
          }
        }

        &-text {
          font-weight: 400;
          font-size: pxToRem(15px);
          color: var(--dark-blue);
          padding-bottom: $spacing-md;
        }

        &-buttons {
          justify-content: space-around;
          padding-bottom: $spacing-md;

          .sb-cta-groups-wrapper {
            @include mq($mq-xs) {
              padding: 0;
            }
          }

          &-button {
            position: relative;
            flex-grow: 1;

            &-icon {
              position: absolute;
              right: 10px;
              width: 28px;
              height: 28px;

              @include mq($mq-md) {
                display: none;
              }

              @include mq($mq-sm) {
                display: block;
              }
            }
          }
        }

        &-testimony {
          padding: $spacing-md;
          border-radius: 10px;
          background-color: var(--bg-grey);
          gap: $spacing-md;

          &-left {
            &-img {
              width: 80px;
              height: 80px;
              border-radius: 90px;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          &-right {
            &-name {
              font-weight: 800;
              font-size: pxToRem(15px);
              padding-bottom: $spacing-sm;

              &-role {
                color: var(--green);
              }
            }

            &-text {
              font-weight: 500;
              font-size: pxToRem(12px);
            }
          }
        }
      }
    }
    &-companies-trust {
      padding-top: $spacing-lg;
      @include mq($mq-sm) {
        text-align: center;
      }
    }
    &-companies {
      align-items: center;
      display: flex;
      flex: 1;
      padding-top: $spacing-md;
    }
  }
  .grayscale {
    filter: grayscale(100%);
    transition: filter 0.3s ease-in-out;
    &:hover {
      filter: grayscale(0);
    }
  }
}
</style>
